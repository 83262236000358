<template lang="pug">
  AppLayout
    Band(contained, centered)
      div(class="mx-auto lg:w-3/4")
        div(v-if="!showingSearchResults")
          Breadcrumbs(:items="parseBreadcrumbs(this.data.folder.breadcrumbs)", v-if="this.data.folder && this.data.folder.breadcrumbs.length > 1")
          div.flex
            h1 {{ pageTitle }}
            div.flex.ml-4.mt-2.space-x-4(v-if="data.folder && ((data.folder.type === 'partner' && user.hasPermission('collections.shared_manage')) || (data.folder.type === 'personal' && user.hasPermission('collections.manage')))")
              a(@click="editFolder()", class="text-supplement hover:text-primary-default"): Icon(icon="pencil")
              a(@click="deleteFolder()", class="text-supplement hover:text-primary-default"): Icon(icon="trash")
        div(v-else)
          h1 Search Results
          p.mt-1 #[strong {{ data.items.length }}] {{ data.items.length === 1 ? 'result' : 'results' }} matching '#[strong {{ searchTerm }}]'

        div.controls
          TextInput(v-model="query", prepend-icon="search", placeholder="Search collections and folders", @input="search()", :spellcheck="false")
          div.controls-item.controls-item--right(
            v-if="(!data.folder && (user.hasPermission('collections.shared_manage') || user.hasPermission('collections.manage'))) || (data.folder && ((data.folder.type === 'partner' && user.hasPermission('collections.shared_manage')) || (data.folder.type === 'personal' && user.hasPermission('collections.manage'))))"
          )
            Button(type="primary", prepend-icon="collection", icon-prefix="fas", @click="newCollection()") New Collection
            Button(type="primary", prepend-icon="folder", icon-prefix="fas", @click="newFolder()") New Folder

        div.collection-browser
          div.item(v-for="item in data.items", :key="item.id", :class="`item--${item.type}-access`")
            div.item-details
              div.item-icon(:class="`item-icon-${item.entity}`"): Icon(
                :icon="item.entity === 'folder' ? 'folder' : 'collection'",
                prefix="fas",
                :style="item.type === 'partner' ? `color: ${brandColor};` : ''"
              )
              div
                //Breadcrumbs(:items="parseBreadcrumbs(item.breadcrumbs)", v-show="showingSearchResults && item.breadcrumbs.length > 1")
                div.item-name: router-link(:to="itemLink(item)", v-html="$options.filters.highlight($markdown(item.name), query)")
                ul.meta.space-x-6
                  li.meta__item(v-if="item.entity === 'folder'")
                    span.list-comma-separated
                      span(v-if="item.folders_count") {{ item.folders_count }} {{ item.folders_count === 1 ? 'folder' : 'folders' }}
                      span(v-if="item.collections_count") {{ item.collections_count }} {{ item.collections_count === 1 ? 'collection' : 'collections' }}
                      span(v-if="!item.folders_count && !item.collections_count") Empty
                  li.meta__item(v-if="item.entity === 'collection'") {{ item.contents_count }} {{ item.contents_count === 1 ? 'item' : 'items' }}
                  li.meta__item.flex.items-center(v-if="item.entity === 'collection' && item.code")
                    Icon(icon="tag")
                    span.list-comma-separated
                      span(v-for="code in item.code", v-html="$options.filters.highlight(code, query)")
            div.item-action(v-if="item.entity === 'collection'")
              a(@click="viewLandingPage(item)"): Icon(icon="eye")
            div.item-action(v-if="item.entity === 'collection'")
              a(v-clipboard="landingPageURL(item)", v-clipboard:success="onCopy"): Icon(icon="link")
            div.item-action(v-if="item.entity === 'collection'")
              a(@click="emailLandingPage(item)"): Icon(icon="envelope")
            div.item-action
              router-link(:to="itemLink(item)"): Icon(icon="chevron-right")

        Pagination.mt-6(:pagination="data.meta", @page-changed="onPageChange")
</template>

<script>
import { Breadcrumbs, Pagination } from "@syntax51/app-kit"
import { debounce, each, get } from "lodash-es"
import NewCollectionModal from "@/modals/Collections/NewCollectionModal"
import NewCollectionFolderModal from "@/modals/CollectionFolders/NewCollectionFolderModal"
import EditCollectionFolderModal from "@/modals/CollectionFolders/EditCollectionFolderModal"
import DeleteCollectionFolderModal from "@/modals/CollectionFolders/DeleteCollectionFolderModal"
import SidebarWidget from "@/components/SidebarWidget"
import { mapGetters } from "vuex"

export default {
  components: {
    Breadcrumbs,
    Pagination,
    SidebarWidget
  },
  watch: {
    $route: "clearSearch"
  },
  metaInfo() {
    return { title: this.pageTitle, bodyAttrs: { class: "page-collections-view-folder" } }
  },
  data: () => ({
    loading: true,
    query: "",
    searching: false,
    showingSearchResults: false,
    data: {},
    type: "all",
    page: 1
  }),
  computed: {
    ...mapGetters({
      user: "auth/user"
    }),
    brandColor() {
      return this.user.get("partner.brand_color")
    },
    endpoint() {
      let { id } = this.$route.params
      return `collections-and-folders/` + (id ? id : this.type)
    },
    pageTitle() {
      return get(this.data, "folder.name", "Collections")
    },
    partnerName() {
      return this.user.get("partner.name")
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    async fetch() {
      this.loading = true
      let params = this.query ? { query: this.query, page: this.page } : { page: this.page }
      let response = await this.$api.get(this.endpoint, { params })
      this.data = response.data
      this.loading = false
    },
    search: debounce(async function () {
      this.page = 1
      this.searching = true
      this.fetch()
      this.showingSearchResults = this.query !== ""
      this.searchTerm = this.query
      this.searching = false
    }, 500),
    clearSearch() {
      this.query = ""
      this.searchTerm = ""
      this.showingSearchResults = false
      this.fetch()
    },
    parseBreadcrumbs(crumbs) {
      let breadcrumbs = [{ label: "Collections", to: { name: "collections" } }]

      each(crumbs, breadcrumb => {
        let crumb = { label: breadcrumb.name }
        if (!breadcrumb.last) crumb.to = { name: "collection.folder", params: { id: breadcrumb.id } }
        breadcrumbs.push(crumb)
      })

      return breadcrumbs
    },
    itemLink(item) {
      let routeName = item.entity === "folder" ? "collection.folder" : "collection"
      return { name: routeName, params: { id: item.id } }
    },
    async newCollection() {
      const response = await this.$modal.show(NewCollectionModal, {
        type: this.data.folder && this.data.folder.type,
        folder_id: this.data.folder && this.data.folder.id
      })

      this.$router.push({ name: "collection", params: { id: response.item.id } })
    },
    async newFolder() {
      if (
        await this.$modal.show(NewCollectionFolderModal, {
          type: this.data.folder && this.data.folder.type,
          folder_id: this.data.folder && this.data.folder.id
        })
      ) {
        this.fetch()
      }
    },
    async editFolder() {
      let response = await this.$modal.show(EditCollectionFolderModal, { folder_id: this.data.folder.id })
      if (response) this.fetch()
    },
    async deleteFolder() {
      let response = await this.$modal.show(DeleteCollectionFolderModal, { folder_id: this.data.folder.id })
      if (response) this.$router.push({ name: "collections" })
    },
    viewLandingPage(collection) {
      window.open(this.landingPageURL(collection), "_blank")
    },
    emailLandingPage(collection) {
      let emailTemplate = this.user.get("landing_page_email_template")

      emailTemplate = emailTemplate.replace("{{collectionLink}}", this.landingPageURL(collection))
      emailTemplate = emailTemplate.replace("{{userName}}", this.user.first_name)

      window.location.href = `mailto:?subject=${encodeURIComponent(collection.name)}&body=${encodeURIComponent(
        emailTemplate
      )}`
    },
    landingPageURL(collection) {
      let landingPageURL = collection.landing_page_url
      let { display_contact_info_on_links } = this.user

      if (display_contact_info_on_links === "personal") {
        landingPageURL += "?uid=" + this.user.get("uid")
      } else if (display_contact_info_on_links === "company") {
        landingPageURL += "?pid=" + this.user.get("partner.pid")
      }

      return landingPageURL
    },
    onPageChange({ page }) {
      this.page = page
      this.fetch()
      // this.$refs.results.scrollIntoView()
    },
    onCopy() {
      this.$notification.success("Copied!")
    }
  }
}
</script>

<style>
@import "@syntax51/app-kit/assets/styles/mixins/list-comma-separated.css";

.page-collections-view-folder {
  .highlight {
    @apply bg-primary-100;
  }

  .breadcrumbs {
    @apply mb-2;
  }

  .controls {
    @apply mt-8 items-center;

    .form-control-textinput {
      @apply rounded-full;

      .form-control-input {
        @apply rounded-full;
      }
    }

    &-item--right {
      @apply mt-4 flex;
      margin-left: -5px;
      margin-right: -5px;

      .button {
        @apply w-1/2;
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  }

  .collection-browser {
    @apply mt-8;

    .card {
      @apply rounded-none bg-transparent shadow-none;
    }

    .item {
      @apply flex items-center rounded-lg bg-white p-8 shadow;

      & + .item {
        @apply mt-3;
      }

      &-details {
        @apply mr-1 flex flex-grow;
      }

      &-name {
        @apply font-semibold;
      }

      &-icon {
        @apply mr-6 flex items-center justify-center rounded-lg text-lg text-supplement;

        &-folder {
          @apply text-2xl;
        }
      }

      &-action {
        @apply ml-1;

        > a {
          @apply flex items-center justify-center text-supplement;
          height: 30px;
          width: 30px;

          &:hover {
            @apply text-primary-default;
          }
        }
      }
    }

    .meta {
      @apply flex text-supplement;

      .icon {
        @apply mr-2;
      }

      &__item {
        @apply mt-1;
      }
    }
  }

  .icon--personal {
    @apply text-supplement;
  }
}

@screen md {
  .page-collections-view-folder {
    .controls {
      @apply flex justify-between;

      .form-control-textinput {
        min-width: 300px;
      }

      &-item--right {
        @apply mt-0;

        .button {
          @apply w-auto;
        }
      }
    }

    .sidebar-widget .button {
      @apply mt-3 block;

      &-prepend {
        @apply w-8 justify-center;
      }
    }
  }
}

@screen lg {
  .page-collections-view-folder {
    .controls {
      .form-control-textinput {
        min-width: 400px;
      }
    }
  }
}
</style>
